import React from "react"
import Seo from "../components/SEO"
import Layout from "../components/Layout"

const HomePage = () => {

  return (
    <>
      <Seo />
      <Layout>
        <h1>My name is <a href='https://linkedin.com/in/michalpkrupa/'>Michał</a>.</h1>
      </Layout>
    </>
  )
}

export default HomePage
